import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '@app/services/locale/locale.service';
import interpolate from '@app/shared/utils/string/interpolate';
import { AffiliationsService } from '../affiliations/affiliations.service';
import { ConfigService } from '@core/config.service';
import { ExpirableSessionStorageService } from '@app/shared/services/expirable-storage/expirable-session-storage.service';
import { CookieService } from 'ngx-cookie-service';
import get from 'lodash-es/get';

@Injectable({
  providedIn: 'root'
})
export class ResourceBundleService {
  url = '/resource-bundle-watcher/${brand}/${locale}/homepage/';
  universalContentUrl = '/i18n/wdpr-ui-universal-layout/${brand}/${locale}'
  defaultAffiliation = 'standard';
  defaultProfile = 'looker';
  currentUserInfo;
  useResourceBundleWindowData = false;
  sessionTTL = 60000 * 4; // 4 minutes
  serviceCalls = {};

  constructor(
    private httpClient: HttpClient,
    private localeService: LocaleService,
    private affiliationService: AffiliationsService,
    private configService: ConfigService,
    private storage: ExpirableSessionStorageService,
    private cookieService: CookieService,
  ) {
    const toggles = this.configService.getValue('toggles');
    if (toggles.resourceBundleStorageTTL) {
      this.sessionTTL = toggles.resourceBundleStorageTTL;
    }
    if (toggles.resource_bundle_window_data) {
      this.useResourceBundleWindowData = true;
    }
  }
  get(type: 'stories' | 'heroes' | 'meta' | 'quickquote' | 'parkHours' | 'countdownClock' | 'universalContent'): Promise<any> {
    return this.setLocaleCookie().then(() => this.getCachedContent(type)).then(content => {
      if (type === 'meta' || type === 'quickquote' || type === 'parkHours') {
        return content[type];
      }

      let storyOrderingPreset;

      if (content.storyOrderingPresets) {
        storyOrderingPreset = content.storyOrderingPresets.presets.A;
      }
      if (type !== 'universalContent') {
        content = this.extractRelevantStoriesData(content, type);
      }

      if (type === 'stories') {
        var dvic = 0;
        for (var i in content) {
          if (content[i].generalPurposeStrings.storyName === 'DvicOffer' && dvic >= 1) {
            content.splice(i, 1);
          } else if (content[i].generalPurposeStrings.storyName === 'DvicOffer') {
            dvic = 1;
          }
        }
      }

      if (content && type !== 'universalContent') {
        content = content.sort(function (a, b) {
          const val = parseInt(a.generalPurposeStrings.order, 10) - parseInt(b.generalPurposeStrings.order, 10);

          return val;
        });
      }

      return { [type]: content, storyOrderingPreset };
    });
  }

  /**
   * Ping the app domain to set the locale cookie. This is to fix to fix US content
   * showing in UK on first page load where locale cookie isn't immediately set. Intl
   * project should fix this since aka locale cookie is set on initial page load. This
   * project is on hold and only set in latest.
   */
  setLocaleCookie(): Promise<any> {
    const localeCookieNames = this.configService.getValue('localeCookieNames');
    const akaLangCookie = get(localeCookieNames, 'akamai.languageSelection');
    const defaultLangCookie = get(localeCookieNames, 'default.languageSelection');
    const isDev = this.configService.getValue('environment') === 'development';

    if (this.cookieService.get(akaLangCookie) || this.cookieService.get(defaultLangCookie) || isDev) {
      return Promise.resolve(true);
    } else {
      return this.httpClient.get<any>('/profile-api/authentication/get-client-token/').toPromise();
    }
  }


  getURL(type) {
    return this.localeService.getLocale().then(locale => {
      locale = locale || 'en';

      const brand = this.configService.getValue('siteId');

      // en_US is not supported on dcl, forcing en
      // TODO: convert to config-based
      if (brand === 'dcl' && locale === 'en_US') {
        locale = 'en';
      }

      // WDW file is case sensitive en_us
      if (brand === 'wdw' && locale === 'en_US') {
        locale = 'en_us';
      }

      // TEMP
      if (brand === 'hkdl') {
        locale = {
          'en': 'en_US',
          'en_US': 'en_US',
          'zh-hk': 'zh_HK',
          'zh_HK': 'zh_HK',
          'zh-cn': 'zh_CN',
          'zh_CN': 'zh_CN',
          'ja': 'ja_JP',
          'ko': 'ko_KR',
          'th': 'th_TH',
          'id': 'id_ID',
          'ms': 'ms_MY'
        }[locale];
      }
      const isUniversalContent = type === 'universalContent';
      if (isUniversalContent && this.configService.getValue('environment') === 'development') {
        this.universalContentUrl = '/i18n/wdpr-ui-universal-layout/wdw/en_us.json';
      }
      const baseUrl = isUniversalContent ? this.universalContentUrl : this.url;
      const params = isUniversalContent ? { brand, locale: `${locale}.json` } : { brand, locale };
      const url = interpolate(baseUrl, params);

      return url;

    });
  }

  private async getCachedContent(type): Promise<any> {
    return this.getURL(type).then(async url => {
      const cache = this.storage.getItem(url);
      const resourceBundleWindowData = window.resourceBundleData;
      // can we move the conditions outside this promise?
      // can we get the current user info from the request?
      const affiliations = await this.affiliationService.getAffiliations();
      this.extractUserInfo(affiliations);

      if (resourceBundleWindowData && this.useResourceBundleWindowData && type === 'stories') {
        return Promise.resolve(resourceBundleWindowData);
      } else if (cache) {
        return Promise.resolve(cache);
      } else {
        // set cache to last 5 minutes = 300000 ms
        const date = new Date();
        const dateTime = Math.round(date.getTime() / 300000);
        const cbUrl = type === 'universalContent' ? url : url + '?time=' + dateTime;

        if (!this.serviceCalls[cbUrl]) {
          this.serviceCalls[cbUrl] = this.httpClient.get<any>(cbUrl).toPromise();
        }

        return this.serviceCalls[cbUrl].then(content => {
          // old homepage didn't have an expire for non-watcher service, but we'll do
          // it for both here since all brands will eventually move to watcher anyway
          const expireTime = new Date().getTime() + this.sessionTTL; // 4 mins in the future
          const expireIsoDate = new Date(expireTime).toISOString();
          this.storage.setItem(url, content, expireIsoDate);

          return content;
        });
      }
    });
  }

  /*
   * Filter content based on the current user's affiliation(s)/profile(s)
   * @param {Object} resource bundle content
   * @param {String} content type to return (heroes|stories)
   * @returns {Array} filtered stories content
   */
  extractRelevantStoriesData(dataBundle, dataSet) {
    const currentUserAffiliations = this.currentUserInfo.affiliations;
    let affiliationPriority = this.affiliationService.getPriorityAffiliations();
    let chosenAffiliation;

    if (dataBundle.affiliationPriority && dataBundle.affiliationPriority.length) {
      affiliationPriority = dataBundle.affiliationPriority;
    }

    /* If there are more than 1 affiliations for the current user choose higher priority affiliation
      * higher index means higher priority
      */
    if (affiliationPriority &&
      affiliationPriority.length > 1 &&
      currentUserAffiliations.length > 1) {
      let currentPriority = -99;
      for (let j = 0; j < currentUserAffiliations.length; j++) {
        const checkAffil = currentUserAffiliations[j];
        let checkIndex = affiliationPriority.indexOf(checkAffil);
        if (checkIndex === -1) {
          checkIndex = affiliationPriority.indexOf(checkAffil.toLowerCase());
        }
        if (checkIndex > currentPriority) {
          const testAffiliation = affiliationPriority[checkIndex];
          let contentFound;
          // countdownClock is option for an affiliation
          if (dataSet === 'countdownClock') {
            contentFound = dataBundle[dataSet][testAffiliation];
          } else {
            contentFound = dataBundle[dataSet][testAffiliation] &&
              dataBundle[dataSet][testAffiliation].length > 0;
          }
          if (contentFound) {
            currentPriority = checkIndex;
            chosenAffiliation = affiliationPriority[checkIndex];
          }
        }
      }
    } else if (affiliationPriority &&
      affiliationPriority.length === 1) {
      chosenAffiliation = affiliationPriority[0];
    } else {
      chosenAffiliation = this.defaultAffiliation;
    }

    return dataBundle[dataSet][chosenAffiliation];
  }

  extractUserInfo(affiliation) {
    const siteId = this.configService.getValue('siteId');
    // Set default affiliation as fallback
    const affiliationList = [this.defaultAffiliation];
    // Create affiliation array and standardize default affiliation
    if (affiliation !== undefined &&
      affiliation[siteId] !== undefined &&
      affiliation[siteId].storedAffiliations !== undefined &&
      affiliation[siteId].storedAffiliations.length !== 0
    ) {
      for (const key in affiliation[siteId].storedAffiliations) {
        if (affiliation[siteId].storedAffiliations[key] !== 'STD_GST') {
          affiliationList.push(affiliation[siteId].storedAffiliations[key]);
        }
      }
    }

    // TODO: add in dynamic profile function instead of static text
    this.currentUserInfo = {
      affiliations: affiliationList,
      profile: this.defaultProfile
    };

    return this.currentUserInfo;
  }

}
declare global {
  interface Window {
    resourceBundleData: object;
  }
}
