import { Injectable } from '@angular/core';
import isObject from 'lodash-es/isObject';
import { of } from 'rxjs/observable/of';
import { GeolocationService } from '../geolocation/geolocation.service';
import { ConfigService } from '@core/config.service';

enum Affiliation {
  STD_GST = 'STD_GST',
  FL_RESIDENT = 'FL_RESIDENT',
  STANDARD_GUEST = 'STANDARD_GUEST',
  CALIFORNIA_RESIDENT = 'CALIFORNIA_RESIDENT',
  BAJA_RESIDENT = 'BAJA_RESIDENT',
  SOUTHERN_CALIFORNIA_RESIDENT = 'SOUTHERN_CALIFORNIA_RESIDENT',
  FLORIDA_RESIDENT = 'FLORIDA_RESIDENT',
  UK_RESIDENT = 'UK_RESIDENT',
  CANADA_RESIDENT = 'CANADA_RESIDENT',
  TEXAS_RESIDEN = 'TEXAS_RESIDEN'
}

export interface StoredAffiliation {
  storedAffiliations?: String[];
}
export interface SiteAffiliations {
  wdw?: StoredAffiliation;
  dlr?: StoredAffiliation;
  dcl?: StoredAffiliation;
  pep?: StoredAffiliation;
  hkdl?: StoredAffiliation;
}

@Injectable({
  providedIn: 'root'
})
export class AffiliationsService {

  affiliationsJar = 'AFFILIATIONS_jar';

  defaultAffiliations: SiteAffiliations = {
    pep: { storedAffiliations: [Affiliation.STD_GST] },
  };

  WEST_USA_RESIDENT = 'WEST_USA_RESIDENT';
  EAST_USA_RESIDENT = 'EAST_USA_RESIDENT';
  WEST_CANADA_RESIDENT = 'WEST_CANADA_RESIDENT';
  EAST_CANADA_RESIDENT = 'EAST_CANADA_RESIDENT';
  FLORIDA_RESIDENT = 'FL_RESIDENT';
  TEXAS_RESIDENT = 'TX_RESIDENT';
  CALIFORNIA_RESIDENT = 'CAL_RESIDENT';
  BAJA_RESIDENT = 'BAJA_RESIDENT';
  SOUTHERN_CALIFORNIA_RESIDENT = 'SOCAL_RESIDENT';
  UK_RESIDENT = 'UK_RESIDENT';
  CANADA_RESIDENT = 'CA_RESIDENT';
  STANDARD_GUEST = 'STD_GST';
  TEXAS_RESIDEN = 'TEXAS_RESIDEN';
  US_RESIDENT = 'US_RESIDENT';
  HK_RESIDENT = 'HK_RESIDENT';
  AU_RESIDENT = 'AU_RESIDENT';
  CN_RESIDENT = 'CN_RESIDENT';
  ID_RESIDENT = 'ID_RESIDENT';
  IN_RESIDENT = 'IN_RESIDENT';
  JP_RESIDENT = 'JP_RESIDENT';
  KR_RESIDENT = 'KR_RESIDENT';
  MS_RESIDENT = 'MS_RESIDENT';
  PI_RESIDENT = 'PI_RESIDENT';
  SG_RESIDENT = 'SG_RESIDENT';
  TH_RESIDENT = 'TH_RESIDENT';
  TW_RESIDENT = 'TW_RESIDENT';
  BR_RESIDENT = 'BR_RESIDENT';
  dcl = {
      // USA
      florida: this.FLORIDA_RESIDENT,
      washington: this.WEST_USA_RESIDENT,
      utah: this.WEST_USA_RESIDENT,
      oregon: this.WEST_USA_RESIDENT,
      nevada: this.WEST_USA_RESIDENT,
      'new mexico': this.WEST_USA_RESIDENT,
      idaho: this.WEST_USA_RESIDENT,
      colorado: this.WEST_USA_RESIDENT,
      california: this.WEST_USA_RESIDENT,
      arizona: this.WEST_USA_RESIDENT,
      texas: this.TEXAS_RESIDENT,
      'new york': this.EAST_USA_RESIDENT,
      pennsylvania: this.EAST_USA_RESIDENT,
      connecticut: this.EAST_USA_RESIDENT,
      'district of columbia': this.EAST_USA_RESIDENT,
      massachusetts: this.EAST_USA_RESIDENT,
      maryland: this.EAST_USA_RESIDENT,
      'rhode island': this.EAST_USA_RESIDENT,
      oklahoma: this.TEXAS_RESIDENT,
      louisiana: this.TEXAS_RESIDENT,
      hawaii: this.WEST_USA_RESIDENT,
      montana: this.WEST_USA_RESIDENT,
      maine: this.EAST_USA_RESIDENT,
      'new hampshire': this.EAST_USA_RESIDENT,
      vermont: this.EAST_USA_RESIDENT,
      'new jersey': this.EAST_USA_RESIDENT,
      delaware: this.EAST_USA_RESIDENT,

      // West Canada
      alberta: this.WEST_CANADA_RESIDENT,
      'british columbia': this.WEST_CANADA_RESIDENT,
      manitoba: this.WEST_CANADA_RESIDENT,
      saskatchewan: this.WEST_CANADA_RESIDENT,

      // East Canada
      'new brunswick': this.EAST_CANADA_RESIDENT,
      'newfoundland and labrador': this.EAST_CANADA_RESIDENT,
      'nova scotia': this.EAST_CANADA_RESIDENT,
      ontario: this.EAST_CANADA_RESIDENT,
      'prince edward island': this.EAST_CANADA_RESIDENT,
      quebec: this.EAST_CANADA_RESIDENT,

      priorityOrder: [
          'standard',
          'west_usa_resident',
          'east_usa_resident',
          'west_canada_resident',
          'east_canada_resident',
          'tx_resident',
          'fl_resident',
      ]
  };
  dlr = {
      // used for DLR content
      california: this.CALIFORNIA_RESIDENT,
      'baja california': this.BAJA_RESIDENT,
      // Zip code range
      CAL_RESIDENT: {
          zipMin: 90000,
          zipMax: 93599,
          affiliation: this.SOUTHERN_CALIFORNIA_RESIDENT
      },
      priorityOrder: [
          'standard',
          'cal_resident',
          'socal_resident'
      ]
  };
  wdw = {
      // used for WDW content
      florida: this.FLORIDA_RESIDENT,
      'united kingdom': this.UK_RESIDENT,
      canada: this.CANADA_RESIDENT,
      priorityOrder: [
          'standard',
          'fl_resident',
          'canada_resident'
      ]
  };
  hkdl = {
    california: this.CALIFORNIA_RESIDENT,
    florida: this.FLORIDA_RESIDENT,
    'au': this.AU_RESIDENT,
    'australia': this.AU_RESIDENT,
    'br': this.BR_RESIDENT,
    'brazil': this.BR_RESIDENT,
    'ca': this.CANADA_RESIDENT,
    'canada': this.CANADA_RESIDENT,
    'china': this.CN_RESIDENT,
    'cn': this.CN_RESIDENT,
    'hong_kong': this.HK_RESIDENT,
    'hong kong': this.HK_RESIDENT,
    'hkg': this.HK_RESIDENT,
    'hk': this.HK_RESIDENT,
    'id': this.ID_RESIDENT,
    'indonesia': this.ID_RESIDENT,
    'india': this.IN_RESIDENT,
    'jp': this.JP_RESIDENT,
    'japan': this.JP_RESIDENT,
    'kr': this.KR_RESIDENT,
    'south_korea': this.KR_RESIDENT,
    'south korea': this.KR_RESIDENT,
    'my': this.MS_RESIDENT,
    'malaysia': this.MS_RESIDENT,
    'ph': this.PI_RESIDENT,
    'philippines': this.PI_RESIDENT,
    'sg': this.SG_RESIDENT,
    'singapore': this.SG_RESIDENT,
    'tw': this.TW_RESIDENT,
    'taiwan': this.TW_RESIDENT,
    'taiwan_province_of_china': this.TW_RESIDENT,
    'th': this.TH_RESIDENT,
    'thailand': this.TH_RESIDENT,
    'gb': this.UK_RESIDENT,
    'united_kingdom': this.UK_RESIDENT,
    'united kingdom': this.UK_RESIDENT,
    'us': this.US_RESIDENT,
    'united_states': this.US_RESIDENT,
    'united states': this.US_RESIDENT,

    [this.CALIFORNIA_RESIDENT]: {
      zipMin: 90000,
      zipMax: 93599,
      affiliation: this.SOUTHERN_CALIFORNIA_RESIDENT
    },
    priorityOrder: [
      'standard',
      this.BR_RESIDENT,
      this.UK_RESIDENT,
      this.CANADA_RESIDENT,
      this.US_RESIDENT,
      this.HK_RESIDENT,
      this.AU_RESIDENT,
      this.CN_RESIDENT,
      this.ID_RESIDENT,
      this.IN_RESIDENT,
      this.JP_RESIDENT,
      this.KR_RESIDENT,
      this.MS_RESIDENT,
      this.PI_RESIDENT,
      this.SG_RESIDENT,
      this.TH_RESIDENT,
      this.TW_RESIDENT,
      'fl_resident',
      this.FLORIDA_RESIDENT,
      this.CALIFORNIA_RESIDENT,
      this.SOUTHERN_CALIFORNIA_RESIDENT
    ]
  };
  pep = Object.assign(this.dlr, this.wdw);

  AFFILIATIONS = {
    'dcl': this.dcl,
    'dlr': this.dlr,
    'wdw': this.wdw,
    'hkdl': this.hkdl,
    'pep': this.pep
  };
  AFFILIATION_STRINGS = {
    'STANDARD': this.STANDARD_GUEST,
    'CALIFORNIA': this.CALIFORNIA_RESIDENT,
    'BAJA': this.BAJA_RESIDENT,
    'SOCAL': this.SOUTHERN_CALIFORNIA_RESIDENT,
    'FLORIDA': this.FLORIDA_RESIDENT,
    'UNITED_KINGDOM': this.UK_RESIDENT,
    'CANADA': this.CANADA_RESIDENT,
    'TEXAS': this.TEXAS_RESIDENT
  };

  affiliationList = this.AFFILIATION_STRINGS;
  affiliationPriorityList;

  setAffiliations = {};

  siteId;

  constructor(
    private geolocationService: GeolocationService,
    private configService: ConfigService
  ) {
    this.siteId = this.configService.getValue('siteId');
    this.affiliationPriorityList = this.AFFILIATIONS[this.siteId].priorityOrder;
  }

  getPriorityAffiliations () {
    return this.affiliationPriorityList;
  }

  getAffiliations() {
    const storage = window.sessionStorage;
    let affiliationsJarData = {};
    let storageAvailable = false;

    // Check if storage is available
    if (storage) {
      try {
        storageAvailable = storage.storageAvailable = true;
      } catch (error) {
          // fail silently
      }
    }

    // If storage is available and affil in storage pull data
    if (storageAvailable && storage[this.affiliationsJar]) {
        affiliationsJarData = JSON.parse(storage[this.affiliationsJar]);
    }

    if (isObject(affiliationsJarData) && affiliationsJarData[this.siteId]) {
      // Affiliation data is set and brand exists
      return Promise.resolve(affiliationsJarData);
    } else {
      const self = this;
      const affiliationData = this.defaultAffiliations[this.siteId] || [];
      const brandAffiliations = this.AFFILIATIONS[this.siteId] || {};

      return this.getGeolocation(brandAffiliations, affiliationData).then(() => {
        console.log('geo: getGeolocation.then');
        if (self.setAffiliations[this.siteId]) {
          return self.setAffiliations[this.siteId](affiliationData);
        } else {
          return Promise.resolve(null);
        }
      }).then(() => {
        // Add this.siteId affiliationData to json object
        affiliationsJarData[this.siteId] = {
          'storedAffiliations': affiliationData
        };

        // Change object to string and place in sessionStorage
        storage[this.affiliationsJar] =  JSON.stringify(affiliationsJarData);

        return affiliationsJarData;
      });
    }
  }

  getGeolocation(possibleAffiliations, affiliations) {
    return new Promise(resolve => {
      this.geolocationService.getGeoCode().subscribe(function(geodata) {
        console.log('geo:', geodata);
        const region = geodata.state || geodata.region;
        const counry = geodata.country.toLowerCase();

        if (possibleAffiliations.hasOwnProperty(region)) {
          const state = possibleAffiliations[region];
          affiliations.push(state);

          if (
            possibleAffiliations[state] &&
            possibleAffiliations[state].zipMin &&
            possibleAffiliations[state].zipMax &&
            possibleAffiliations[state].affiliation
          ) {
              const zip = geodata.zip || geodata.zipCode,
                  zipMin = possibleAffiliations[state].zipMin,
                  zipMax = possibleAffiliations[state].zipMax;

              if (zip >= zipMin && zip <= zipMax) {
                affiliations.push(possibleAffiliations[state].affiliation);
              }
          }
        }

        if (possibleAffiliations.hasOwnProperty(counry)) {
          affiliations.push(possibleAffiliations[counry]);
        }

        resolve(null);
      });
    });
}

}
