<div id="homepage-top">
  <pixie-hero
    [id]="id"
    [title]="title"
    [ctas]="ctas"
  ></pixie-hero>
  <pixie-countdown-clock></pixie-countdown-clock>
  <pixie-hkdl-quick-quote
    class="hkdl-quick-quote"
    *ngIf="siteId==='hkdl' && utilsConfig && hkdlResourceBundle"
    [config]="utilsConfig"
    [resourceBundle]="hkdlResourceBundle">
  </pixie-hkdl-quick-quote>
  <a
      *ngIf="parkHoursBundle"
      class=""
      [href]="parkHoursBundle.href | absTrimmer:false:true"
  >
    <div class="park-hours">
      <div class="park-hours-icons"><div class="di di-norgie-closed"></div></div>
      <div class="park-hours-label"><span class="" [innerHtml]="parkHoursBundle.title | safeHtml"></span></div>
    </div>
  </a>
</div>

<pixie-stories appDeepLink></pixie-stories>
