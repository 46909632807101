import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last } from 'lodash';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Affiliation } from '@interfaces/affiliation';
import { ConfigService } from '@app/core/config.service';
import { SESSION_MANAGER } from '@app/app.constants';
import { SpecialOfferResponse } from '@interfaces/special-offer-response';
import { SpecialOfferSale } from '@interfaces/special-offer-sale';

@Injectable({
    providedIn: 'root'
})
export class SpecialOffersService {
    private specialOfferId: string;
    private sessionManagerURL: string;
    private specialOffers: SpecialOfferResponse;

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) {
        this.sessionManagerURL = this.configService.getValue(SESSION_MANAGER);
    }

    /**
     * Retrieve the Marketing offer
     * @returns special offer id
     */
    getSpecialOfferId(): string {
        return this.specialOfferId;
    }

    /**
     * Sets the Marketing offer
     * @param marketingOffer New Marketing offer
     */
    setSpecialOfferId(specialOfferId: string): void {
        this.specialOfferId = specialOfferId;
    }

    /**
     * Retrieve special offers
     * @returns Special offers
     */
    getSpecialOffers(): Observable<SpecialOfferResponse> {
        if (this.specialOffers) {
            return of(this.specialOffers);
        } else {
            return this.httpClient.get<SpecialOfferResponse>(`${this.sessionManagerURL}`).pipe(
                tap((response: SpecialOfferResponse) => {
                    this.specialOffers = response;
                })
            );
        }
    }

    /**
     * Update special offers object
     * @param data object to save
     * @returns Special offers
     */
    putSpecialOffers(data: SpecialOfferSale): Observable<SpecialOfferResponse> {
        return this.httpClient.put<SpecialOfferResponse>(`${this.sessionManagerURL}`, data).pipe(
            tap((response: SpecialOfferResponse) => {
                this.specialOffers = response;
            })
        );
    }

    /**
     * Delete special offers object
     * @returns Special offers
     */
    deleteSpecialOffers(): Observable<null> {
        delete this.specialOfferId;
        delete this.specialOffers;

        return this.httpClient.delete<null>(`${this.sessionManagerURL}`);
    }

    /**
     * Return the last affiliation retrieved from the session manager
     * https://wiki-wdpro.disney.com/display/Tech/PA+VAS+-+Product+Availability+-+Retrieve+Products
     *
     * @param affiliationsPromoCode param comming from promocode
     * @returns Affiliations
     */
    getAffiliations(affiliationsPromoCode: string[]): Affiliation[] {
        const affiliations: Affiliation[] = [];
        const lastAffilliation: string = last(affiliationsPromoCode);

        if (lastAffilliation) {
            affiliations.push({
                affiliationType: lastAffilliation
            });
        }

        return affiliations;
    }
}
