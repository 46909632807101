import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router/';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from '@app/shared/components/components.module';

import { CoreModule } from './core/core.module';
import { ConfigService } from './core/config.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AnalyticsModule } from '@wdpr/angular-analytics';
import { SeoModule, SeoOptionsInterface } from '@wdpr/ra-angular-seo-metadata';
import { GeolocationService, GEO_LOCATION_CONFIG } from './services/geolocation/geolocation.service';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';

import { HomepageModule } from './features/homepage/hkdl/homepage.module';
import { CookieService } from 'ngx-cookie-service';
import { LocaleService } from '@app/services/locale/locale.service';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FeatureToggleModule, FEATURE_TOGGLE_CONFIG } from '@wdpr/ra-angular-feature-toggle';
import { AuthHttpInterceptor } from './core/interceptors/auth-interceptor';
import { TravelAgentInterceptor } from './core/interceptors/travel-agent.interceptor';
import { ErrorLogHttpInterceptor } from './core/interceptors/error-log.interceptor';
import { UniversalResourcesDirective } from './shared/directives/universal-resources.directive';
import { ContextualFilterService } from '@wdpr/dcl-ui-components-library';

@NgModule({
  declarations: [
    AppComponent,
    UniversalResourcesDirective
  ],
  exports: [
    AppComponent,
    ComponentsModule
  ],
  imports: [
    AnalyticsModule,
    FeatureToggleModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    SeoModule,
    HomepageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ContextualFilterService,
    TravelAgentInterceptor,
    AuthHttpInterceptor,
    ErrorLogHttpInterceptor,
    LocaleService,
    GeolocationService,
    HomepageAnalyticsService,
    CookieService,
    Title,
    {
      provide: 'SEO_OPTIONS',
      useValue: {
        customSeoRetrieval: true
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => {
        return config.load().then((result: any) => {
          return result;
        }).catch((err) => {
          return err;
        });
      },
      deps: [ConfigService],
      multi: true
    },
    {
      provide: 'PAGE_KEY_URL_ENDPOINT',
      useValue: '/wdpr-web-dcl-homepage/page-key',
    },
    {
      provide: 'PAGE_KEY_TEMPLATE',
      useValue: [
        'siteId', 'path'
      ],
    },
    {
      provide: GEO_LOCATION_CONFIG,
      useValue: {
        URL: '/wdpr-web-dcl-homepage/geocode/',
        COOKIE_AKA_HKDL: 'geolocation_aka_hkdl_jar',
        COOKIE_AKA: 'geolocation_aka_jar',
        COOKIE_JAR: 'GEOLOCATION_jar',
        ISO_COUNTRY_MAP: {
          'US': 'united+states',
          'CA': 'canada',
          'AR': 'argentina',
          'MX': 'mexico',
          'GB': 'united+kingdom',
          'PE': 'peru',
          'CO': 'colombia',
          'CL': 'chile',
          'BR': 'brazil'
        },
        REGION_MAP: {
          'CA': 'california',
          'FL': 'florida'
        }
      }
    },
    {
      provide: 'LOGASAURUS_URL',
      useValue: '/wdpr-web-dcl-homepage/error'
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/wdpr-ui-components-plus/', '.json');
}
