import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from '@core/config.service';
import { HttpClient } from '@angular/common/http';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { LocaleService } from '@app/services/locale/locale.service';
import { get } from 'lodash-es';

declare const window: any;

@Component({
  selector: 'pixie-dvic-banner',
  templateUrl: './dvic-banner.component.html',
  styleUrls: ['./dvic-banner.component.scss']
})

export class DvicBannerComponent implements OnInit {
    inViewObserver: IntersectionObserver;
    preferredLanguage;
    langSelection;
    geolocation;
    geolocationTwo;
    selectedLanguage;
    bannerOverride;
    dvicToggle;
    tokenCookie;
    authToken;
    webOrMobile;
    globalSiteId;
    environment;
    dvicScript;
    dvicimage;
    stories;

  constructor(
      private cookieService: CookieService,
      private configService: ConfigService,
      private httpClient: HttpClient,
      private localeService: LocaleService,
      private resourceBundle: ResourceBundleService,
    ) { }

  ngOnInit() {
        if (window.screen.width <= 480) {
            this.webOrMobile = 'mobile_web';
        } else {
            this.webOrMobile = 'web';
        }
        this.globalSiteId = 'dcl';
        this.dvicBanner();

        this.resourceBundle.get('stories').then(data => {
            this.stories = data.stories;
        });

        return window.screen.width;
    }

    getCookie(cname: string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return null;
    }

    setInstancePropertyToCookieValue(cookieKey: string, localVariable: string, objectKey: string): void {
        // get cookie value json and convert to object
        const cookieValue = this.getCookie(cookieKey);
        const cookieObject = JSON.parse(cookieValue);
        if (!cookieValue || !cookieObject) {
            return;
        }
        // set instance property to value by cookie key
        this[localVariable] = get(cookieObject, objectKey);
    }

    dvicBanner() {
        this.setInstancePropertyToCookieValue('localeCookie_jar', 'preferredLanguage', 'preferredRegion');
        this.setInstancePropertyToCookieValue('localeCookie_jar', 'langSelection', 'contentLocale');
        this.setInstancePropertyToCookieValue('localeCookie_jar_aka', 'preferredLanguage', 'preferredRegion');
        this.setInstancePropertyToCookieValue('geolocation_aka_jar', 'geolocation', 'country');
        this.setInstancePropertyToCookieValue('AFFILIATIONS_jar', 'geolocation', 'dcl.storedAffiliations[0]');
        this.setInstancePropertyToCookieValue('AFFILIATIONS_jar', 'geolocationTwo', 'dcl.storedAffiliations[1]');

        // this.preferredLanguage = 'en_US'; // FOR TESTING ONLY
        // this.geolocation = 'USA'; //FOR TESTING ONLY

        this.selectedLanguage = this.preferredLanguage;

        if (/_USA_RESIDENT$/i.test(this.geolocation) || /US_RESIDENT$/i.test(this.geolocationTwo)) {
            this.geolocation = 'usa';
        }

        if (/^usa?$/i.test(this.geolocation)) {
            if (/^en([-_]us)?$/i.test(this.preferredLanguage)) {
                this.selectedLanguage = 'en_US';
            }
        }

        if (this.langSelection && this.preferredLanguage === 'en') {
            this.selectedLanguage = 'en_US';
        }

        this.readBannerToggle();
    }

    readBannerToggle() {
        this.environment = this.configService.getValue('environment');
        // this.environment = 'stage'; // FOR TESTING ONLY
        let cdnPrefix = 'stage.';
        if (this.environment === 'prod') {
            cdnPrefix = '';
        }
        let configPrefix = this.environment ? this.environment : 'latest';
        // TODO when s3 access is restored: this.httpClient.get('https://' + cdnPrefix + 'cdn1.parksmedia.wdprapps.disney.com/media/home/' + configPrefix + '/configs/homepage-banner-toggles.json')
        this.httpClient.get('https://' + cdnPrefix + 'cdn1.parksmedia.wdprapps.disney.com/media/home/homepage-banner-toggles.json')
        .subscribe(json => {
            const bannerOverride = this.getCookie('dvicBannerToggle');
            if (bannerOverride) {
                const override = bannerOverride.split(',');
                if (override[0] === this.globalSiteId && override[1] === this.environment && override[2] === 'off') {
                    console.log('dvic turned off');

                    return json;
                }
            }
            this.tokenCookie = this.getCookie('tokenSwid');

            const showimage = this.getCookie('showbannnerimage');
            if (this.tokenCookie) {
                console.log('tokenSwid Cookie enabled');
                this.showDvicBanner();

                return json;
            }
            if (showimage) {
                this.showDvicBannerImage();

                return json;
            }
            this.dvicToggle = json;
            if (this.dvicToggle[this.globalSiteId][this.environment] === 'on') {
                this.showDvicBanner();

                return json;
            }
            if (this.dvicToggle[this.globalSiteId][this.environment] === 'off') {
                console.log('showing default banner image');
                this.showDvicBannerImage();

                return json;
            }
        }, error => { console.log('Error read banner toggle: ', error); });
    }

    public showDvicBanner() {
        let token;
        if (this.tokenCookie) {
            token = this.tokenCookie.split(',')[0];
        }
        if (!token) {
            this.httpClient.get('/profile-api/authentication/get-client-token/').subscribe(json => {
                this.authToken = json['access_token'];
                this.dvicBannerCall();
            }, error => {
                console.log('error: could not get authentication token');
                console.log(error);
            });
        } else {
            this.authToken = token;
            this.dvicBannerCall();
        }
    }

    showDvicBannerImage() {
        if (this.webOrMobile ===  'mobile_web') {
           // use mobile photo
           this.dvicimage = 'mobile_web';
        } else {
            // use desktop photo
            this.dvicimage = 'web';
        }
    }

    dvicBannerCall() {
        let myswid;
        let userProfile;
        this.httpClient.get('/profile-api/authentication/status/').subscribe(json => {
            if (json['isLoggedIn']) {
                userProfile = json['swid'];
            }

            try {
                if (typeof userProfile !== 'undefined') {
                    myswid = userProfile;
                }
            } catch (error) {
                console.log(error);
            }
            if (this.tokenCookie) {
                const swid = this.tokenCookie.split(',');
                if (swid.length > 1) {
                    myswid = swid[1];
                }
            }

            if (this.environment === 'lt01') {
                this.environment = 'load';
            }

            if (this.environment === 'latest') {
                this.dvicScript = 'https://latest.cdn1.parksmedia.wdprapps.disney.com/media/dvic-banner/'
                + this.globalSiteId + '/wdpr-dvic-banner.js';
            } else if (this.environment === 'stage' || this.environment === 'load') {
                this.dvicScript = 'https://stage.cdn1.parksmedia.wdprapps.disney.com/media/dvic-banner/'
                + this.globalSiteId + '/wdpr-dvic-banner.js';
            } else {
                this.dvicScript = 'https://cdn1.parksmedia.wdprapps.disney.com/media/dvic-banner/'
                + this.globalSiteId + '/wdpr-dvic-banner.js';
            }

            const script = document.createElement('script');
                script.type = 'text/javascript';
                script.id = 'dvicBannerEmbedScript';
                script.async = true;
                script.src = this.dvicScript;
                document.getElementsByTagName('body')[0].appendChild(script);
            
            window.onReadyDvicBanner = () => {
                console.log('banner ready');
                window.dvicBanner
                    .configuration({
                    apiPrefixPath: '/dvic', // remove when testing locally
                    serviceTimeout: 5000,
                    accessToken: this.authToken,
                    envName: this.environment, // 'stage'
                    languageHeader: this.selectedLanguage, // 'en_US' or 'en-US'
                    params: {
                        siteId: this.globalSiteId, // 'dcl'
                        pageId: 'home',
                        device: this.webOrMobile,
                        swid: myswid
                    }
                })
                .init();
                window.dvicBanner.loadBanner();
            };
            let flagBannerSeen = false;
            window.onDvicBannerDisplay = function(bannerrData) {
                if (document.getElementById('wdpr-dvic-banner')) {
                    console.log('dvic analytics available');

                    const banner = document.getElementById('wdpr-dvic-banner');
                    this.inViewObserver = new IntersectionObserver((entries) => {
                        if (entries[0].isIntersecting && !flagBannerSeen) {
                            this.inViewObserver.unobserve(banner);
                            console.log('banner seen, sending impression');
                            if (bannerrData.hasOwnProperty('analytics') && bannerrData.analytics.hasOwnProperty('impression')) {
                                (window.DisneyDataLayer || (window.DisneyDataLayer = [])).push([
                                    'trackElement',
                                    bannerrData.analytics.impression
                                ]);
                            }
                            var order;
                            for (var i in this.stories) {
                                if (this.stories[i].generalPurposeStrings.storyName === 'DvicOffer') {
                                    order = this.stories[i].generalPurposeStrings.order;
                                }
                            }
                            var urlFriendly = this.globalSiteId.toUpperCase() + '_DVIC_' + document.getElementsByClassName('dvicBanner')[0].attributes['data-analytics'].value;
                            var dvicAnalytics = order + ':DVIC:' + urlFriendly;
                            var addDvicSeen = window.localStorage.getItem('analyticslist') ? window.localStorage.getItem('analyticslist') + ',' + dvicAnalytics : window.localStorage.setItem('analyticslist', dvicAnalytics);
                            window.localStorage.setItem('analyticslist', addDvicSeen);

                            flagBannerSeen = true;
                        }
                    });
                    this.inViewObserver.observe(banner);

                    window.onDvicBannerClick = function(data) {
                        if (data.hasOwnProperty('analytics') && data.analytics.hasOwnProperty('click')) {
                            (window.DisneyDataLayer || (window.DisneyDataLayer = [])).push([
                                'trackElement',
                                data.analytics.click
                            ]);
                        }
                    };
                }
            }.bind(this);
            window.onDvicBannerError = function(err) {
                console.log('[DVC Banner JS]', err);
            };
        }, error => {
            console.log('error: could not get logged in status');
            console.log(error);
        });


    }

}
