import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { Affiliation } from '@interfaces/affiliation';
import { AffiliationData } from '@interfaces/affiliation-data';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigService } from '@app/core/config.service';
import { COOKIES, ENDPOINTS, DEFAULT_APP_VALUES, AFFILIATION_TYPES } from '@app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    affiliations: Affiliation[];
    webApiUrl: string;
    cookies = COOKIES;

    constructor(
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private httpClient: HttpClient
    ) {
        this.webApiUrl = this.configService.getValue('webApiUrl');
    }

    /**
     * Returns list of affiliations (e.g. PASSHOLDER, FL_RESIDENT etc)
     * @returns Observable of Affiliations
     */
    getAffiliations(): Observable<Affiliation[]> {
        return this.authenticationService.isLoggedIn().pipe(
            switchMap((isLogged: boolean) => {
                if (isLogged) {
                    if (this.affiliations) {
                        return of(this.affiliations);
                    } else {
                        const SWID = this.cookieService.get(COOKIES.SWID);
                        const affiliationsUrl = `${this.webApiUrl}${ENDPOINTS.affiliations}`.replace('{swid}', SWID);

                        return this.httpClient.get<AffiliationData>(affiliationsUrl)
                            .pipe(
                                map((response: AffiliationData) => {
                                    const affiliations: Affiliation[] = [];
                                    // Just only use ther first affiliation with affiliationType === CASTAWAYCLUB
                                    const firstAffilliation: Affiliation = response.entries.find(
                                        (entry: Affiliation) => entry.affiliationType === AFFILIATION_TYPES.CASTAWAY
                                    );

                                    if (firstAffilliation) {
                                        affiliations.push({
                                            affiliationType: firstAffilliation.affiliationType,
                                            affiliationLevel: firstAffilliation.affiliationLevel || firstAffilliation.level,
                                            affiliationId: firstAffilliation.affiliationId || firstAffilliation.clubId
                                        });
                                    }

                                    return affiliations;
                                }),
                                tap((response: Affiliation[]) => this.affiliations = response),
                                catchError((): Observable<Affiliation[]> => {
                                    // TODO: The error handling for this http call should be updated
                                    // when the specification for errors is defined
                                    return of(DEFAULT_APP_VALUES.affiliations as Affiliation[]);
                                })
                            );
                    }
                } else {
                    return of(DEFAULT_APP_VALUES.affiliations as Affiliation[]);
                }
            })
        );
    }
}
