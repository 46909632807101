<div class="dcl-layout">
    <section id="stories-container">
        <ng-container *ngFor="let story of stories; let i=index;" [ngSwitch]="story.generalPurposeStrings.template">
            <pixie-lead-gen-dcl
                *ngSwitchCase="'lead-gen'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-lead-gen-dcl>
            <pixie-revenue
                *ngSwitchCase="'5-revenue-hero'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-revenue>
            <pixie-destination
                *ngSwitchCase="getDestinationTemplate(story.generalPurposeStrings.template)"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-destination>
            <pixie-crosspromo
                *ngSwitchCase="'cross-promo'"
                [id]="getStoryId(story.generalPurposeStrings, i)" 
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid" >
            </pixie-crosspromo>
            <pixie-vpg
                *ngSwitchCase="'dcl-banner'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-vpg>
            <pixie-awards
                *ngSwitchCase="'awards'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-awards>
            <pixie-dvic-banner
                *ngSwitchCase="'dvic-offer'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-dvic-banner>
            <pixie-featured-product
                *ngSwitchCase="'featured-cruise-tile'"
                [id]="getStoryId(story.generalPurposeStrings, i)"
                [attr.story-index]="i"
                [site-id]="siteId"
                [desktop-mobile]="desktopOrMobile"
                [data]="story"
                [story-order]="storyOrder"
                class="story template-{{story.generalPurposeStrings.template}} container-fluid">
            </pixie-featured-product>

        </ng-container>
    </section>
</div>