<hkdl-quick-quote
    *ngIf="isDataReady"
    [globalConfig]="globalConfigs"
    [content]="content"
    [hotelsQuickQuoteConstraints]="hotelsConstraints"
    [ticketsQuickQuoteConstraints]="ticketConstraints"
    [hotelsValidationErrors]="hotelsValidationErrors"
    [ticketsValidationErrors]="ticketsValidationErrors"
    [loggerService]="loggerService"
    (submitHotelsForm)="hotelFormSubmission($event)"
    (submitTicketsForm)="ticketFormSubmission($event)">
</hkdl-quick-quote>
