import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuickQuoteService } from '@wdpr/dcl-ui-components-library';
import { CruiseListingAnalyticsModel } from '@interfaces/cruise-listing-analytics-model';
import { FilterOptionData } from '@interfaces/filter-option-data';
import { CruiseListingDataService } from '@services/cruise-listing/cruise-listing.service';
import { ConfigService } from '@app/core/config.service';
import { TravelAgentService } from '@wdpr/dcl-ui-components-library';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { ANALYTICS_CONSTANTS } from '@app/services/analytics/analytics.constants';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from '@app/app.constants';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { LocaleService } from '@app/services/locale/locale.service';
import { get } from 'lodash';

@Component({
    selector: 'pixie-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {
    id = '';
    title = '';
    ctas = '';
    cruiseListURL = '';
    contextPath = '';
    filters: FilterOptionData;
    isDclClRefactorPhase2: boolean;
    isDclAdvancedBooking: boolean;
    isDclAdvancedBookingPhase2: boolean;
    isDclAdvancedBookingPhase3: boolean;
    siteId;
    baseUrl: string;
    utilsConfig;
    hkdlResourceBundle;
    parkHoursBundle;

    constructor(
        private quickQuoteService: QuickQuoteService,
        private cruiseListingService: CruiseListingDataService,
        private configService: ConfigService,
        private travelAgentService: TravelAgentService,
        private analyticsService: HomepageAnalyticsService,
        private httpClient: HttpClient,
        private resourceBundleService: ResourceBundleService,
        private localeService: LocaleService
    ) { }

    ngOnInit() {
        const featureToggles = this.configService.getValue('featureToggles');
        this.siteId = this.configService.getValue('siteId');
        this.isDclClRefactorPhase2 = !!(featureToggles && featureToggles.dclClRefactorPhase2);
        this.isDclAdvancedBooking = !!(featureToggles && featureToggles.dclAdvancedBooking);
        this.isDclAdvancedBookingPhase2 = !!(featureToggles && featureToggles.dclAdvancedBookingPhase2);
        this.isDclAdvancedBookingPhase3 = !!(featureToggles && featureToggles.dclAdvancedBookingPhase3);
        this.cruiseListURL = this.configService.getValue('cruiseListURL');
        this.quickQuoteService.onApplyFilters.subscribe((data: string[]) => this.onApplyFilters(data));

        if (this.siteId === 'dcl') {
            this.setDCLQQFilterOptions();
        }

        if (this.siteId === 'hkdl') {
            this.initHkdlQuickQuoteConfig();
            this.initHkdlQuickQuoteContent();
            this.initParkHours();
        }

        this.travelAgentService.setFlowEnabled(true);
        this.travelAgentService.setRestBaseUrl(this.configService.getValue('travelAgentWebApiUrl'));
    }

    onApplyFilters(filters: string[] = []): void {
        /*
        if (window.location.hash) {
            console.log('qq hash ', window.location.hash);
            this.cruiseListURL += window.location.hash;
        }
        */
       window.location.href = this.cruiseListURL;
    }

    setDCLQQFilterOptions(): void {
        this.cruiseListingService.getFilters().subscribe(
            (filters: FilterOptionData) => {
                this.contextPath = 'wdpr-web-dcl-homepage';
                this.filters = filters;
            },
            () => console.log('qq error')
        );
    }

    /**
     * Receives the analytics data from the respective triggered Event Emitter
     * @param analyticsData object with the values needed for Analytics Function.
     */
    analyticsTrackingEvent(analyticsData: CruiseListingAnalyticsModel): void {
        console.log('qq analytics event');
        analyticsData.pageVariant = ANALYTICS_CONSTANTS.PAGE_VARIANT;
        analyticsData.bookingSearchPartySize = analyticsData.bookingSearchPartySize;
        analyticsData.bookingSearchNumResults = analyticsData.bookingSearchNumResults;
        analyticsData.facetsUtilized = analyticsData.facetsUtilized;
        analyticsData.productsDisplayedEvent = analyticsData.productsDisplayedEvent;
        analyticsData.productsDisplayed = analyticsData.productsDisplayed;
        this.analyticsService.trackLink(analyticsData.trackLinkID, analyticsData);
    }

    /**
     * Make call to utils and d-scribe to get the configs and contents for HKDL QQ
     */
    public initHkdlQuickQuoteConfig(): void {
        this.localeService.getLocale().then((locale: string) => {
            locale = locale || 'en';
            const localePrefix  = {
                'en': 'en_US',
                'en_US': 'en_US',
                'zh_HK':  'zh-hk',
                'zh_CN': 'zh-cn',
                'ja_JP': 'ja',
                'ko_KR': 'ko',
                'th_TH': 'th',
                'id_ID': 'id',
                'ms_MY': 'ms'
              };
            locale = localePrefix[locale] || locale;
            locale = (locale === 'en_US') ? '' : ('/' + locale);
            this.httpClient.get(
                `${locale}${ENDPOINTS.utilsConfig}`
            ).subscribe((config) => {
                this.utilsConfig = config;
            });
        });
    }

    /**
     * Get the content from d-scribe call
     */
    public initHkdlQuickQuoteContent(): void {
        this.resourceBundleService.get('quickquote').then((resourceBundle) => {
            this.hkdlResourceBundle = resourceBundle;
        });
    }

    public initParkHours(): void {
        this.resourceBundleService.get('parkHours').then((resourceBundle) => {
            const val = get(resourceBundle, 'modules[1].descriptions[0].webLinks[0]', null);
            this.parkHoursBundle = val;
        });
    }
}
