import { Component, OnInit, Input } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';
import { ConfigService } from '@app/core/config.service';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { GeoLocationInfo } from './services/geolocation/geolocation.info';
import { LocaleService } from '@app/services/locale/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { ResourceBundleService } from './services/resource-bundle/resource-bundle.service';
import { AffiliationsService } from '@app/services/affiliations/affiliations.service';
import { CookieService } from 'ngx-cookie-service';
import { DEFAULT_SOCIAL_TAGS } from '@app/app.constants';
import { get } from 'lodash';
import {
  ME_CONFIG,
  getClosedCaptioningUrl,
  getResizeImageUrl,
  getDescriptiveTranscriptUrl,
  getVideoDetailSources,
  getVideoMainSources,
  getDefaultImageUrl,
  dataHasValueAtKey,
  getMediaEngineId
} from './shared/utils/media-engine';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public siteId: string;
  @Input() dataType: string;

  title = 'wdpr-pixie-page';
  environment: string;
  seoUrl: string;
  geolocation: GeoLocationInfo;
  affiliations: Object;
  content;
  useSyndicated: Boolean = true;
  mediaKeyBaseLegacy = 'heroes[0].descriptions.';
  isMobile = false;
  hasFullScreenVideo = false;
  ME: any;
  mediaEngineId: string;
  fullscreen = false;
  locale: string = 'en';
  themes: string[] = [];

  constructor(
    private logger: LoggerService,
    private seoService: SeoService,
    private configService: ConfigService,
    private homepageAnalyticsService: HomepageAnalyticsService,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private resourceBundle: ResourceBundleService,
    private localeService: LocaleService,
    private affiliationsService: AffiliationsService,
    private cookieService: CookieService
  ) {
    this.siteId = this.configService.getValue('siteId');
    this.environment = this.configService.getValue('environment');
    this.resourceBundle.get('heroes').then(data => {
      const seoImageUrl = this.getSeoImageUrl(data);
      this.resourceBundle.get('meta').then(meta => {
        // Add social meta tags
        this.metaService.addTags(this.getSocialTags(meta || {}, seoImageUrl));
        if (meta) {
          this.setupSeo(meta);
        }
      });
    });
    this.setupSyndicated();
    this.setupAnalytics();
    this.setThemes();
    if (this.siteId === 'wdw') {
      this.setupPersonalization();
    }

    this.translate.addLangs(['pt-br', 'es-ar', 'es-cl', 'es-co', 'es-mx', 'es-pe', 'es-es', 'en']);
    const appComponentMediaEngineId = getMediaEngineId('appComponent', 1);
    this.mediaEngineId = appComponentMediaEngineId;
  }

  ngOnInit(): void {
    const MEConfigKey = `${this.mediaEngineId}Config`;
    window[MEConfigKey] = ME_CONFIG;

    this.localeService.getLocale().then((locale: string) => {
      this.locale = locale || 'en';
      this.translate.setDefaultLang(this.locale);
      this.translate.use(this.locale);
      this.setLangAttribute();
    });

    this.resourceBundle.get('heroes').then(data => {
      if (this.siteId === 'hkdl') {
        // Add data for Media Engine
        if (dataHasValueAtKey(data, 'videoMain', this.isMobile)) {
          this.dataType = 'video';
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: getVideoMainSources(data, this.isMobile),
            preview: getVideoMainSources(data, this.isMobile),
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            title: this.siteId,
            alt: this.siteId,
            tags: ['a']
          });
        } else {
          this.dataType = 'image';
          const accessibility = {
            labels: {
              en: {
                meTitle: get(data, 'heroes[0].media.defaultImage.title', '')
              }
            }
          };
          window[MEConfigKey].accessibility = accessibility;
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: [getDefaultImageUrl(data, this.isMobile)],
            preview: [getDefaultImageUrl(data, this.isMobile)],
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            title: this.siteId,
            alt: this.siteId,
            tags: ['a'],
          });
        }

        if (dataHasValueAtKey(data, 'videoDetail', this.isMobile)) {
          this.hasFullScreenVideo = true;
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            source: getVideoDetailSources(data, this.isMobile),
            preview: getVideoDetailSources(data, this.isMobile),
            poster: getResizeImageUrl(getDefaultImageUrl(data, this.isMobile)),
            closedCaptioning: getClosedCaptioningUrl(data, this.isMobile),
            descriptiveTranscript: getDescriptiveTranscriptUrl(data, this.isMobile),
            title: this.siteId,
            alt: this.siteId,
            tags: ['b']
          });
        }
      } else {
        if (get(data, 'heroes[0].descriptions.defaultImage.sections.source', false)
          && data.heroes[0].descriptions.defaultImage.sections.source.length > 1) {
          this.dataType = 'image';

          // This gets used from within the frame
          window[MEConfigKey].media.push({
            type: 'image',
            thumbnail: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            source: [
              data.heroes[0].descriptions.defaultImage.sections.source
            ],
            preview: [
              data.heroes[0].descriptions.defaultImage.sections.source
            ],
            poster: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            title: this.siteId,
            alt: this.siteId,
            tags: ['a']
          });
        } else if (get(data, 'heroes[0].descriptions', false)) {
          this.hasFullScreenVideo = true;
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            source: [
              data.heroes[0].descriptions.videoMain.sections.source1,
              data.heroes[0].descriptions.videoMain.sections.source2
            ],
            preview: [
              data.heroes[0].descriptions.videoMain.sections.source1,
              data.heroes[0].descriptions.videoMain.sections.source2
            ],
            poster: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            title: this.siteId,
            alt: this.siteId,
            tags: ['a']
          });
          window[MEConfigKey].media.push({
            type: 'video',
            thumbnail: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            source: [
              data.heroes[0].descriptions.videoDetail.sections.source1,
              data.heroes[0].descriptions.videoDetail.sections.source2
            ],
            preview: [
              data.heroes[0].descriptions.videoDetail.sections.source1,
              data.heroes[0].descriptions.videoDetail.sections.source2
            ],
            poster: getResizeImageUrl(
              data.heroes[0].descriptions.defaultImage.sections.source
            ),
            closedCaptioning: data.heroes[0].descriptions.videoDetail.sections.closedCaption,
            descriptiveTranscript: data.heroes[0].descriptions.videoDetail.sections.descriptiveTranscript,
            title: this.siteId,
            alt: this.siteId,
            tags: ['b']
          });
        }
      }
      try {
        // @ts-ignore
        this.ME = new window.ME3();
        // this instance of the ME is set on the window because it's call to fullscreen is invoked in the hero component
        window[this.mediaEngineId] = this.ME;
        this.ME.init('.hero-me', window[MEConfigKey]);
      } catch (error) {
        console.log('error initializing me3', error);
      }

      const pixieSelector = this.siteId === 'hkdl' ? 'pixie-hero' : 'pixie-hero-dcl';
      window['initConfigOverrides'] = () => {
        if (this.hasFullScreenVideo) {
          this.ME.config.callbacks.customFullscreenEnter = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            document.body.classList.add('pixie-fullscreen');
            document.querySelector(pixieSelector).classList.add('fade-out-fade-in');
            document.getElementById('media-engine-hero').classList.add('me-fullscreen');
            this.ME.changeMediaSet(2);
            this.ME.fullscreenEnter();
            this.fullscreen = true;
          };
          this.ME.config.callbacks.customFullscreenExit = () => {
            document.body.classList.remove('pixie-fullscreen');
            document.querySelector(pixieSelector).classList.add('fade-out-fade-in');
            document.getElementById('media-engine-hero').classList.remove('me-fullscreen');
            this.ME.fullscreenExit();
          };
        } else {
          this.ME.config.callbacks.customFullscreenEnter = () => { };
          this.ME.config.callbacks.customFullscreenExit = () => { };
        }
      };

      window.addEventListener('scroll', () => {
        if (this.fullscreen && window.scrollY > 250) {
          this.ME.config.callbacks.customFullscreenExit();
        }
      });
    });
  }

  private setupSyndicated() {
    if (document.getElementById('pageContainerSyndicated')) {
      this.useSyndicated = false;
    }
    console.log('this.useSyndicated  ', this.useSyndicated);
  }

  private setupAnalytics() {
    this.homepageAnalyticsService.initAnalytics();
  }

  private setupPersonalization() {
    console.log('personalization');
  }

  private setupSeo(data: any) {
    this.seoService.setSeoData(data);
    this.titleService.setTitle(data.pageTitle);
  }

  private getStringArray(value): string[] {
    const array = typeof value === 'string' && value ? value.split(',') : [];

    return array.map(value => value.trim());
  }

  private setThemes() {
    const toggles = this.configService.getValue('toggles');
    const themes = this.getStringArray(toggles?.homepageTheme);
    const unthemedAffiliations = this.getStringArray(toggles?.unthemedAffiliations);
    const overrideThemes = this.getStringArray(this.cookieService.get('theme'));

    // Add themes from testing cookie set in browser
    if (overrideThemes.length > 0) themes.push(...overrideThemes);

    if (themes.length === 0) return;

    if (unthemedAffiliations.length === 0) {
      this.themes = themes;
      document.body.classList.add(...this.themes);

      return;
    }

    // Check for affiliations that match unthemedAffiliations
    // If there are any matches, do not set themes from the toggles file
    this.affiliationsService.getAffiliations().then(affiliations => {
      const storedAffiliations = affiliations[this.siteId]?.storedAffiliations || [];
      const matches = storedAffiliations.filter(affiliation => unthemedAffiliations.includes(affiliation));

      this.themes = matches.length > 0 ? overrideThemes : themes;
      document.body.classList.add(...this.themes);
    });
  }

  private getSeoImageUrl(data: any): string {
    const heroData = data?.heroes[0];
    // Get image url from media default image if it exists
    const mediaDefaultImage = heroData?.media?.defaultImage;
    const mediaDefaultImageUrl = mediaDefaultImage?.url || (mediaDefaultImage?.sections && mediaDefaultImage?.sections?.source) || '';
    // Otherwise get image url from descriptions
    const descriptions = heroData?.descriptions;
    const descImageUrl = descriptions?.videoMain?.sections?.display || descriptions?.defaultImage?.sections?.source || '';

    return mediaDefaultImageUrl || descImageUrl;
  }

  private getSocialTags(data: any, seoImageUrl: string): { property?: string, name?: string, content: string }[] {
    let tagsContent = DEFAULT_SOCIAL_TAGS[this.siteId];
    tagsContent = {
      ...tagsContent,
      // Update URL in case a different language is used
      url: window.location.href,
      // Update title and description with meta data if it exists
      pageTitle: data?.pageTitle || tagsContent?.pageTitle,
      description: data?.description || tagsContent?.description,
      // Update with hero imageUrl if it exists
      image: seoImageUrl || tagsContent?.image
    };

    const socialTags: { property?: string, name?: string, content: string }[] = [
      { property: "og:title", content: tagsContent.pageTitle },
      { property: "og:site_name", content: tagsContent.siteName },
      { property: "og:url", content: tagsContent.url },
      { property: "og:description", content: tagsContent.description },
      { property: "og:type", content: tagsContent.type },
      { property: "og:image", content: tagsContent.image },
      { name: "twitter:card", content: tagsContent.twitterCard },
      { name: "twitter:site", content: tagsContent.twitterSite },
      { name: "twitter:title", content: tagsContent.pageTitle },
      { name: "twitter:description", content: tagsContent.description },
      { name: "twitter:image", content: tagsContent.image }
    ];

    // Remove any tags with undefined or null content
    // If brand doesn't exist in DEFAULT_SOCIAL_TAGS, some values will be undefined
    return socialTags.filter(tag => !!tag.content);
  }

  private setLangAttribute() {
    let lang = this.locale.replace('_', '-').toLowerCase();
    if (window.location?.pathname?.length > 1) {
      lang = this.getLangFromPath();
    }
    if (lang !== 'en') {
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }
  }

  private getLangFromPath() {
    const localeRegExp = /^\/([a-z]{2}((_|-)[A-z]{2})?)(\/|$)/;
    let lang = "en-us";
    const origin = window.location.origin;
    if (origin.endsWith(".eu")) {
      lang = "en-eu";
    } else if (origin.endsWith(".co.uk")) {
      lang = "en-gb";
    } else {
      const currentLocaleFromPath = window.location.pathname.match(localeRegExp)?.[0] || "";
      lang = currentLocaleFromPath ? currentLocaleFromPath.toLowerCase().replace(/\//g, "").replace(/_/g, "-") : lang;
    }

    return lang;
  }
}

declare global {
  interface Window {
    ME3: object;
  }
}