import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { get } from 'lodash-es';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import Tick from '@pqina/flip';


@Component({
  selector: 'pixie-countdown-clock',
  templateUrl: './countdown-clock.component.html',
  styleUrls: ['./countdown-clock.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountdownClockComponent implements OnInit {

    data = {
        sections: {
            cta: "/world-of-frozen/",
            media1: '',
            mobileMedia1: '',
            mobileMedia2: '',
            medialottie1: {
                url: ''
            },
            medialottie2: {
                url: ''
            },
            footer_text: '',
            ctaText: '',
            citizenLogo: '',
            targetDay: ''
        }
    };
    desktopBackgroundPlate;
    mobileBackgroundPlate;
    lottie;
    gated = false;
    visible = false;
    desklottie;
    mobilelottie;

    mobileOptions: AnimationOptions = {
        path: '',
        autoplay: true,
        loop: true,
    };

    desktopOptions: AnimationOptions = {
        path: '',
        autoplay: true,
        loop: true,
    };

    animationCreated(animationItem: AnimationItem): void {
        animationItem.playSegments([[0,200],[190,200]], true);
    }

  constructor(
    private resourceBundle: ResourceBundleService,
  ) { }

    ngOnInit() {
        this.resourceBundle.get('countdownClock').then(result => {
            const content = get(result, 'countdownClock[0].descriptions.countdownClock', false);
            if (content) {
                this.data = content;
                this.mobileBackgroundPlate = this.data.sections.mobileMedia1;
                this.desktopBackgroundPlate = this.data.sections.media1;
                this.desklottie = this.data.sections.medialottie1;
                 // ✔️✔️ Update `options` in this way
                 this.desktopOptions = {
                    ...this.desktopOptions, // In case you have other properties that you want to copy
                    path: this.desklottie,
                };

                var isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
                if (isAndroid) {
                    this.mobilelottie = this.data.sections.mobileMedia2;
                    this.mobileOptions = {
                        ...this.mobileOptions, // In case you have other properties that you want to copy
                        path: this.mobilelottie,
                    };
                } else {
                    this.mobilelottie = this.data.sections.medialottie2;
                    this.mobileOptions = {
                        ...this.mobileOptions, // In case you have other properties that you want to copy
                        path: this.mobilelottie,
                    };
                }

                document.getElementsByTagName('pixie-countdown-clock')[0]['style'].display = 'block';
                var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (isBrowserSafari) {
                    document.getElementsByClassName('clock_wrapper')[0].classList.add('safari-clock');
                }
                this.clockStart(this.data);
            }
        }, (error) => {
            console.error('Failure loading countdown data:', error);
        });
    }

    clockStart(theData) {
        var data = theData;
        var checkDom = setInterval(function() {
            if (document.getElementById('ticker') && Tick) {
                clearInterval(checkDom);
                this.clock = document.getElementById('ticker');
                var tick;
                if (data.sections.daysOnly && data.sections.daysOnly === 'on') {
                    tick = Tick.DOM.create(this.clock, {
                        value: [0],
                        didInit: function(tick) {
                                var locale;
                                    locale = {
                                        DAY_PLURAL: '',
                                        DAY_SINGULAR: ''
                                    };

                                for (var key in locale) {
                                    if (!locale.hasOwnProperty(key)) { continue; }
                                    tick.setConstant(key, locale[key]);
                                }

                                this.endDate = data.sections ? (new Date(data.sections.targetDay)) : '';

                                // create counter
                                var counter = Tick.count.down(this.endDate, {format: ['d']});
                                
                                // update tick with the counter value
                                counter.onupdate = function(value) {
                                    tick.value = value;

                                    var current_day = tick.value[0];

                                    this.ariaClock = 'Remaining time is ' + current_day + ' day(s) ';
                                };
                                this.clock.style.visibility = 'visible';
                                counter.onended = function() {
                                    this.clock.style.visibility = 'hidden';
                                }.bind(this);
                                
                        }.bind(this)
                    });
                    document.getElementById('ticker').classList.add('daysOnly');
                } else {
                    tick = Tick.DOM.create(this.clock, {
                        value: [0,0,0,0],
                        didInit: function(tick) {
                                var locale;
                                    locale = {
                                        DAY_PLURAL: data.sections.days,
                                        DAY_SINGULAR: data.sections.day,
                                        HOUR_PLURAL: data.sections.hours,
                                        HOUR_SINGULAR: data.sections.hour,
                                        MINUTE_PLURAL: data.sections.minutes,
                                        MINUTE_SINGULAR: data.sections.minute,
                                        SECOND_PLURAL: data.sections.seconds,
                                        SECOND_SINGULAR: data.sections.second,
                                    };

                                for (var key in locale) {
                                    if (!locale.hasOwnProperty(key)) { continue; }
                                    tick.setConstant(key, locale[key]);
                                }

                                this.endDate = data.sections ? (new Date(data.sections.targetDay)) : '';

                                // create counter
                                var counter = Tick.count.down(this.endDate, {format: ['d', 'h' ,'m', 's']});
                                
                                // update tick with the counter value
                                counter.onupdate = function(value) {
                                    tick.value = value;

                                    var current_day = tick.value[0];
                                    var current_hour = tick.value[1];
                                    var current_minute = tick.value[2];

                                    this.ariaClock = 'Remaining time is ' + current_day + ' day(s) ' + current_hour + ' hour(s) and ' + current_minute + ' minute(s)';
                                };
                                this.clock.style.visibility = 'visible';
                                counter.onended = function() {
                                    this.clock.style.visibility = 'hidden';
                                }.bind(this);
                        }.bind(this)
                    });
                }
            }
        }, 100);
    }
}
