import { catchError, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, isEmpty, get as getValue } from 'lodash';

import { WhatsIncludedSvcParams } from '@wdpr/dcl-ui-components-library';

import { Affiliation } from '@interfaces/affiliation';
import { AvailabilityBodyParam } from '@interfaces/availability-body-param';
import { AvailableProductsData } from '@interfaces/available-products-data';
import { CruiseListingServiceError } from './cruise-listing-error.interface';
import { ConfigService } from '@app/core/config.service';
import { DEFAULT_APP_VALUES, ENDPOINTS } from '@app/app.constants';
import { FilterOptionData } from '@interfaces/filter-option-data';
import { ProfileService } from '../profile/profile.service';
import { SailingsDataResponse } from '@interfaces/sailing-data-response';
import { SpecialOffersService } from '../special-offers/special-offers.service';

@Injectable({
    providedIn: 'root'
})
export class CruiseListingDataService {
    private defaultAvailParams: AvailabilityBodyParam;
    private webApiUrl: string;

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private profileService: ProfileService,
        private specialOffersService: SpecialOffersService
    ) {
        // Common default availability body params
        this.defaultAvailParams = {
            currency: DEFAULT_APP_VALUES.currency,
            filters: [],
            partyMix: [],
            region: 'DEFAULT_APP_VALUES.region',
            storeId: DEFAULT_APP_VALUES.storeId
        };
        this.webApiUrl = this.configService.getValue('webApiUrl');
    }

    /**
     * Get available products
     * @param params Product's params to retrieve
     * @returns Available products
     */
    getAvailableProducts(params: AvailabilityBodyParam): Observable<AvailableProductsData | CruiseListingServiceError> {
        console.log('getAvailableProducts');

        return this.profileService.getAffiliations().pipe(
            switchMap((affiliations: Affiliation[]) => {
                const bodyParams = merge({}, this.defaultAvailParams, params);

                if (!params.promoCode) {
                    bodyParams.affiliations = affiliations;
                }

                return this.httpClient.post<AvailableProductsData>(`${this.webApiUrl}${ENDPOINTS.availableProducts}`, bodyParams);
            }),
            catchError((error: HttpErrorResponse) => {
                return this.handleCruiseListingServiceError(error);
            })
        );
    }

    /**
     * Get available sailings
     * @param params Sailing's params to retrieve
     * @returns Available sailings
     */
    getAvailableSailings(params: AvailabilityBodyParam): Observable<SailingsDataResponse | CruiseListingServiceError> {
        console.log('getAvailableSailings');

        return this.profileService.getAffiliations().pipe(
            switchMap((affiliations: Affiliation[]) => {
                const bodyParams = merge({}, this.defaultAvailParams, params);

                if (!params.promoCode) {
                    bodyParams.affiliations = affiliations;
                }

                return this.httpClient.post<SailingsDataResponse>(`${this.webApiUrl}${ENDPOINTS.availableSailings}`, bodyParams);
            }),
            catchError((error: HttpErrorResponse) => {
                return this.handleCruiseListingServiceError(error);
            })
        );
    }

    /**
     * Retrieves the filters options data for the Quick Quote
     * @param portOfCall port of call
     * @param availabilityParam Product's params to retrieve
     * @returns Filter options
     */
    getFilters(locale?: string): Observable<FilterOptionData | CruiseListingServiceError> {
        console.log('getFilters');

        return this.profileService.getAffiliations().pipe(
            switchMap((affiliations: Affiliation[]) => {
                // set cache to last 5 minutes = 300000 ma
                const date = new Date();
                const dateTime = Math.round(date.getTime() / 300000);
                let params = new HttpParams().set('time', dateTime.toString());

                if (!isEmpty(affiliations)) {
                    params = params.set('affiliations', JSON.stringify(affiliations[0]));
                }

                let env = this.configService.getValue('environment');
                if (env === 'load') {
                    env = 'lt01.';
                } else if (env !== 'prod') {
                    env = env + '.';
                } else {
                    env = '';
                }
                const includeLanguage = !!locale;
                let headers = { 'X-Page-Id': 'https://' + env + 'disneycruise.disney.go.com', 'accept-language': locale };
                headers = includeLanguage ? { ...headers, "accept-language": locale } : headers;

                return this.httpClient.get<FilterOptionData>(`${this.webApiUrl}${ENDPOINTS.filters}`, { params, headers });
            })
        );
    }

    /**
     * Retrieves whats included modal params used to consume the service
     * @returns Whats included modal service params
     */
    getWhatsIncludedModalSvcParams(): WhatsIncludedSvcParams {
        console.log('getWhatsIncludedModalSvcParams');

        return {
            url: `${this.webApiUrl}${ENDPOINTS.whatsIncludedModal}`,
            language: DEFAULT_APP_VALUES.language
        };
    }

    /**
     * Handles the http response for cruise listing service errors and rethrows an error of type CruiseListingServiceError
     * @param error The http error response
     * @returns The cruise service listing error including the initial http response
     * plus any additional handling info.
     */
    private handleCruiseListingServiceError(error: HttpErrorResponse): Observable<CruiseListingServiceError> {
        const errorCode = getValue(error, 'error.errorCode') || getValue(error, 'error.message.errorCode');
        const cruiseListingServiceError: CruiseListingServiceError = {
            httpErrorResponse: error,
            isCastError: false
        };

        return throwError(cruiseListingServiceError);
    }
}
