import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { ConfigService } from '@app/core/config.service';
import { GeolocationService } from '../geolocation/geolocation.service';

@Injectable()
export class LocaleService {
    private localeCookieNames: any;
    private siteId: string;
    private enableLatamLocales: boolean;
    private enableEuropeLocales: boolean;

    private UK_CONTENT = 'en_GB';
    private EU_CONTENT = 'en_EU';
    private AS_CONTENT = 'en_AS';
    private akaLocaleCookieExists = document.cookie.match(/localeCookie_jar_aka/);

    private SUPPORTED_GEO_LOCALES: any = {
        'default': 'en', // Default
        // By Country ISO Code
        'usa': 'en_US',
        'can': 'en_CA',
        'gib': this.UK_CONTENT,
        'imn': this.UK_CONTENT,
        'gbr': this.UK_CONTENT,
        'ggy': this.UK_CONTENT,
        'jey': this.UK_CONTENT,
        'nld': this.EU_CONTENT,
        'deu': this.EU_CONTENT,
        'irl': this.EU_CONTENT,
        'bel': this.EU_CONTENT,
        'nor': this.EU_CONTENT,
        'swe': this.EU_CONTENT,
        'dnk': this.EU_CONTENT,
        'grc': this.EU_CONTENT,
        'prt': this.EU_CONTENT,
        'icl': this.EU_CONTENT,
        'che': this.EU_CONTENT,
        'mlt': this.EU_CONTENT,
        'pol': this.EU_CONTENT,
        'aut': this.EU_CONTENT,
        'rou': this.EU_CONTENT,
        'fin': this.EU_CONTENT,
        'cze': this.EU_CONTENT,
        'cyp': this.EU_CONTENT,
        'hrv': this.EU_CONTENT,
        'est': this.EU_CONTENT,
        'hun': this.EU_CONTENT,
        'svk': this.EU_CONTENT,
        'alb': this.EU_CONTENT,
        'svn': this.EU_CONTENT,
        'mco': this.EU_CONTENT,
        'bgr': this.EU_CONTENT,
        'reu': this.EU_CONTENT,
        'sau': this.AS_CONTENT,
        'kwt': this.AS_CONTENT,
        'isr': this.AS_CONTENT,
        'are': this.AS_CONTENT,
        'qat': this.AS_CONTENT,
        'egy': this.AS_CONTENT,
        'bhr': this.AS_CONTENT,
        'tur': this.AS_CONTENT,
        'lbn': this.AS_CONTENT,
        'jor': this.AS_CONTENT,
        'omn': this.AS_CONTENT,
        'irq': this.AS_CONTENT,
        'aus': 'en_AU',
        'nzl': 'en_NZ'
    };

    private SUPPORTED_LANGS: any = {
        'en': ['es-us'],
        'en_CA': ['fr-ca'],
        'en_US': ['es-us']
    };

    private SUPPORTED_CURRENCIES = {
        'en': 'USD',
        'en_US': 'USD',
        'es-us': 'USD',
        'en_GB': 'GBP',
        'en_CA': 'USD',
        'en_EU': 'EUR',
        'en_AS': 'USD',
        'en_AU': 'AUD',
        'en_NZ': 'NZD',
        'pt_BR': 'BRL',
        'de_DE': 'EUR',
        'es_ES': 'EUR',
        'es_AR': 'ARS',
        'es_MX': 'MXN',
        'es_PE': 'PEN',
        'es_CO': 'COP',
        'es_CL': 'CLP',
        'it_IT': 'EUR',
        'fr_FR': 'EUR',
        'fr-ca': 'EUR'
    };

    constructor(
        private cookieService: CookieService,
        private configService: ConfigService,
        private loggerService: LoggerService,
        private geolocationService: GeolocationService
    ) {
        // Default to en_US when akamai cookie exists
        if (this.akaLocaleCookieExists) {
            this.SUPPORTED_GEO_LOCALES.default = 'en_US';
        }

        this.siteId = this.configService.getValue('siteId');
        if (this.siteId === 'dcl') {
            this.SUPPORTED_GEO_LOCALES['usa'] = 'en';
        }

        this.localeCookieNames = this.configService.getValue('localeCookieNames');

        // Remove section when int'l completea and set to always add these toggled locales
        const featureToggles = this.configService.getValue('featureToggles');
        this.enableLatamLocales = !!(featureToggles && featureToggles.enableHomepageLatamLocales);
        this.enableEuropeLocales = !!(featureToggles && featureToggles.enableHomepageEuropeLocales);
        // End Remove section when int'l completea and set to always add these toggled locales

        if (this.enableLatamLocales) {
            this.SUPPORTED_GEO_LOCALES['bra'] = 'pt_BR';
            this.SUPPORTED_GEO_LOCALES['arg'] = 'es_AR';
            this.SUPPORTED_GEO_LOCALES['chl'] = 'es_CL';
            this.SUPPORTED_GEO_LOCALES['col'] = 'es_CO';
            this.SUPPORTED_GEO_LOCALES['mex'] = 'es_MX';
            this.SUPPORTED_GEO_LOCALES['per'] = 'es_PE';
            this.SUPPORTED_GEO_LOCALES['esp'] = 'es_ES';
        }

        if (this.enableEuropeLocales) {
            this.SUPPORTED_GEO_LOCALES['deu'] = 'de_DE';
            this.SUPPORTED_GEO_LOCALES['ita'] = 'it_IT';
            this.SUPPORTED_GEO_LOCALES['fra'] = 'fr_FR';
            this.SUPPORTED_GEO_LOCALES['frca'] = 'fr_CA';
        }
    }

    /**
     * Gets the locale from the language selection cookie.
     * @returns {string} the locale string.
     */
    getLocale() {
        const languageCookieValue = this.getLanguageCookie();
        const localeCookieValue = this.getLocaleCookie();

        let result;

        if (localeCookieValue) {
            result = localeCookieValue.preferredRegion || localeCookieValue.contentLocale;
        }
            if (languageCookieValue) {
                const prefLang = languageCookieValue.preferredLanguage;
                const prefList = this.SUPPORTED_LANGS[result];

                if (prefList) {
                    if (prefList.includes(prefLang)) {
                        result = prefLang;
                    }
                }
            }

        // then go to service
        if (!result) {
            return new Promise(resolve => {
                this.geolocationService.getGeoCode().subscribe(geodata => {
                    let selectedLocale = this.SUPPORTED_GEO_LOCALES.default;

                    // Find supported language, if available
                    if (this.SUPPORTED_GEO_LOCALES.hasOwnProperty(geodata.countryisocode)) {
                        selectedLocale = this.SUPPORTED_GEO_LOCALES[geodata.countryisocode];
                    } else if (this.SUPPORTED_GEO_LOCALES.hasOwnProperty(geodata.continent)) {
                        selectedLocale = this.SUPPORTED_GEO_LOCALES[geodata.continent];
                    }

                    resolve(selectedLocale);
                });
            });
        }

        return Promise.resolve(result);
    }

    /**
     * Gets the value of the language selection cookie.
     * @returns {any} the cookie value object or undefined
     */
    getLanguageCookie(): any {
        return this.getCookieObjectValue(
            get(this.localeCookieNames, 'akamai.languageSelection'), get(this.localeCookieNames, 'default.languageSelection')
        );
    }

    /**
     * Gets the value of the locale cookie.
     * @returns {any} the cookie value object or undefined
     */
    getLocaleCookie(): any {
        return this.getCookieObjectValue(get(this.localeCookieNames, 'akamai.locale'), get(this.localeCookieNames, 'default.locale'));
    }

    /**
     * Gets the cookie value as object.
     * Checks the fallback cookie name if the fist one doesn't exist.
     * @param cookieName the cookie name.
     * @param defaultCookieName the fallback cookie name.
     */
    private getCookieObjectValue(cookieName, defaultCookieName): Object {
        let result;
        let cookieValue = this.cookieService.get(cookieName);
            cookieValue = cookieValue ?
                cookieValue : this.cookieService.get(defaultCookieName);

        // Make sure there's something in the cookie to parse first
        if (isEmpty(cookieValue)) {
            return result;
        }

        try {
            result = JSON.parse(cookieValue);
        } catch (e) {
            this.loggerService
                .error('locale-service:::Error parsing the locale cookie: is not a valid object');
        }

        return result;
    }
}
