<section class="title-and-ctas">
  <h1
    *ngIf="isMobile && titleMobile"
    class="cta-title"
    [innerHTML]="titleMobile | safeHtml"
    (click)="enterFullscreen($event)"></h1>
  <section *ngIf="isMobile" class="ctas">
    <div class="ctas-holder">
      <a
        *ngIf="hasMobileBackgroundVideo"
        class="cta play-full-video"
        href="#"
        name="&lid={{ctasMobile[0]['analyticsLinkId']}}"
        title="{{ctasMobile[0]['alt']}}"
        target="{{ctasMobile[0]['target']}}"
        aria-hidden="false"
        (click)="enterFullscreen($event)">
        {{ctasMobile[0]['title']}}
        <span class="icon" aria-hidden="true"></span>
      </a>
      <a
        class="cta details-cta"
        href="{{ctasMobile[1]['ctaURL'] | absTrimmer:true:true}}"
        target="{{ctasMobile[1]['target']}}"
        name="&lid={{ctasMobile[1]['analyticsLinkId']}}"
        title="{{ctasMobile[1]['alt']}}"
        aria-hidden="false">
        {{ctasMobile[1]['title']}}
        <span class="icon" aria-hidden="true"></span>
      </a>
    </div>
  </section>
  <h1
    *ngIf="!isMobile && titleDesktop"
    class="cta-title"
    [innerHTML]="titleDesktop | safeHtml"
    (click)="enterFullscreen($event)"></h1>
  <section *ngIf="!isMobile" class="ctas">
    <div class="ctas-holder">
      <a
        *ngIf="hasDesktopBackgroundVideo"
        class="cta play-full-video"
        href="#"
        name="&lid={{ctasDesktop[0]['analyticsLinkId']}}"
        title="{{ctasDesktop[0]['alt']}}"
        target="{{ctasDesktop[0]['target']}}"
        aria-hidden="false"
        (click)="enterFullscreen($event)">
        {{ctasDesktop[0]['title']}}
        <span class="icon" aria-hidden="true"></span>
      </a>
      <a
        class="cta details-cta"
        href="{{ctasDesktop[1]['ctaURL'] | absTrimmer:true:true}}"
        name="&lid={{ctasDesktop[1]['analyticsLinkId']}}"
        title="{{ctasDesktop[1]['alt']}}"
        target="{{ctasDesktop[1]['target']}}"
        aria-hidden="false">
        {{ctasDesktop[1]['title']}}
        <span class="icon" aria-hidden="true"></span>
      </a>
    </div>
  </section>
</section>
<a
  *ngIf="!isMobile"
  class="cta-fullscreen"
  href="{{ctasDesktop[1]['ctaURL'] | absTrimmer:true:true}}"
  name="&lid={{ctasDesktop[1]['analyticsLinkIdFullscreen']}}"
  title="{{ctasDesktop[1]['alt']}}"
  target="{{ctasDesktop[1]['target']}}"
  aria-hidden="false">
  {{ctasDesktop[1]['title']}}
  <span class="icon" aria-hidden="true"></span>
</a>
<div class="hero-me me" id="media-engine-hero"></div>