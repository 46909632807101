<div class="countdown">
    <a aria-label="background" href="{{data.sections.cta}}">
        <div class="clock_wrapper">
            <img class="background desk_clock" src="{{desktopBackgroundPlate}}" alt="Countdown Clock">
            <img class="background mobile_clock" src="{{mobileBackgroundPlate}}" alt="Countdown Clock">

            <div class="desktop-lottie">
                <ng-lottie id="lottie_outro_desktop" width="100%" containerClass="moving-box another-class" [options]="desktopOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
            <div class="mobile-lottie">
                <ng-lottie id="lottie_outro_mobile" containerClass="moving-box another-class" [options]="mobileOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
        </div>
    </a>

    <div class="tick-wrapper">
        <div class="tick"
            id="ticker"
            data-did-init="vm.handleTickInit">

            <div data-repeat="true"
                data-layout="horizontal center fit"
                data-transform="preset(d, h, m, s) -> delay">

                <div class="tick-group">

                    <div data-key="value"
                        data-repeat="true"
                        data-transform="pad(00) -> split -> delay">

                        <span data-view="flip"></span>

                    </div>

                    <span data-key="label"
                        data-view="text"
                        class="tick-label"
                        data-transform="upper"></span>
            
                </div>
            </div>
        </div>
    </div>

    <div class="cta-wrapper" aria-label="cta-wrapper">
        <a href="{{data.sections.cta}}"><button type="button" id="cta">{{data.sections.ctaText}}</button></a>
    </div>

    <div class="timekeeper" id="citizen-logo" aria-label="timekeeper">
        <img class="citizenLogo" aria-label="Citizen Logo" src="{{data.sections.citizenLogo}}">
        <h3 id="citizen-text" class="citizen-text">{{data.sections.footer_text}}</h3>
    </div>
</div>
