import { Component, Input, OnInit } from '@angular/core';
import { VisibleMqService } from '@app/services/visible-mq/visible-mq.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pixie-story-block-item',
  templateUrl: './story-block-item.component.html',
  styleUrls: ['./story-block-item.component.scss']
})
export class StoryBlockItemComponent implements OnInit {

  @Input() item: any = {
    type: '',
  };
  renderImage = false;

  constructor(private mqService: VisibleMqService) { }

  ngOnInit() {
    if (this.item.type === 'image') {
      if (this.item.classes.match(/(^|\s)(visible|hidden)-\S*/g)) {
        this.mqService.currentMediaChanged$.pipe(debounceTime(50)).subscribe(currMedia => this.mqHandler(currMedia.className));
        this.mqHandler(this.mqService.currentMedia.className);
      } else {
        this.renderImage = true;
      }
    }
  }

  mqHandler(mqClassName): void {
    this.renderImage = this.item.classes.indexOf(mqClassName) > -1;
  }
}
