export const QUICK_QUOTE_CONSTANTS = {
    analytics: {
        'city': {
            applyFilterPrefix: 'Ports:',
            filterButtonLink: 'ports',
            cardButtonLink: 'PortCard'
        },
        'date': {
            applyFilterPrefix: 'Dates:',
            filterButtonLink: 'dates',
            cardButtonLink: 'MonthCard'
        },
        'destination': {
            applyFilterPrefix: 'Dest:',
            filterButtonLink: 'destinations',
            cardButtonLink: 'DestinationCard'
        },
        'privateIsland': {
            applyFilterPrefix: 'Dest:',
            filterButtonLink: 'destinations',
            cardButtonLink: 'DestinationCard'
        },
        'travel-party': {
            accessible: '_AccessableRoom',
            accessibleDeselect: '_AccessableRoom_Deselect',
            filterButtonLink: 'guests',
            addAdult: '_AddAdult',
            removeAdult: '_RemoveAdult',
            addChild: '_AddChild',
            removeChild: '_RemoveChild',
            stateroom: '_Stateroom',
            stateroomPrefix: 'Rooms:',
            travelPartyPrefix: 'Party:',
            typeFilters: {
                child: 'Child',
                adult: 'Adult',
                stateroomRemove: 'StateroomRemove',
                stateroomAdd: 'StateroomAdd',
                accessibleAdd: 'AccessibleAdd',
                accessibleRemove: 'AccessibleRemove',
                add: 'Add',
                remove: 'Remove',
                stateroom: 'Stateroom'
            }
        },
        'more-filters': {
            filterButtonLink: 'moreFilters',
            nightFilterPrefix: 'Night:',
            shipFilterPrefix: 'Ship:',
            specialFilterPrefix: 'Special:',
            'night': 'NumOfNights',
            'ship': 'Ship',
            'theme': 'Specialty',
            'new-sailings': 'NewSailings'
        },
        advBooking: {
            prefix: 'Advance:',
            name: 'AdvanceBooking',
            phase2: 'New Sailings'
        },
        contextualFilter: {
            contextualPrefix: 'contextual_',
            clickAction: 'Click',
            nightFilterPrefix: 'NumOfNights_',
            impressionAction: 'Imp'
        },
        actionAdd: 'Add',
        actionRemove: 'Remove',
        analyticsSubjectEvent: 'analyticsEvent',
        applyFiltersButtonLink: 'applyFilters',
        backButtonLink: 'Back',
        clearFiltersButtonLink: 'ClearFilters',
        clearAllButtonLink: 'ClearAll',
        cruiseFiltersLink: 'cruise_filters_',
        doneButtonLink: 'Confirm',
        facets_utilized: {
            action_add: 'Add',
            action_remove: 'Remove'
        },
    },
    cookie: {
        name: 'facetFilters',
        defaultLocale: 'en-us',
        localeCookie: 'localeCookie_jar_aka'
    },
    filters: {
        'date': {
            id: 'date',
            headingKey: 'quickQuote.headings.dates',
            nameKey: 'quickQuote.filterNames.dates',
            defaultValue: 'quickQuote.subHeadings.dates',
            countValue: 'quickQuote.subHeadings.countDates',
            renderOrder: 0,
            wrapperLabel: 'quickQuote.filterLabels.dates'
        },
        'destination': {
            id: 'destination',
            headingKey: 'quickQuote.headings.destinations',
            nameKey: 'quickQuote.filterNames.destinations',
            defaultValue: 'quickQuote.subHeadings.destinations',
            countValue: 'quickQuote.subHeadings.countDestinations',
            renderOrder: 1,
            wrapperLabel: 'quickQuote.filterLabels.destinations'
        },
        'privateIsland': {
            id: 'privateIsland',
            headingKey: 'quickQuote.headings.privateIsland',
            nameKey: 'quickQuote.filterNames.privateIsland',
            defaultValue: 'quickQuote.subHeadings.privateIsland',
            countValue: 'quickQuote.subHeadings.countprivateIsland',
            renderOrder: 5,
            wrapperLabel: 'quickQuote.filterLabels.privateIsland',
            header: {
                titleSingular: 'quickQuote.headings.privateIslandSingular',
                titlePlural: 'quickQuote.headings.privateIslandPlural',
                subtitleSingular: 'quickQuote.filterLabels.privateIslandSingular',
                subtitlePlural: 'quickQuote.filterLabels.privateIslandPlural'
            },
            isSubgroup: true
        },
        'city': {
            id: 'city',
            headingKey: 'quickQuote.headings.departures',
            nameKey: 'quickQuote.filterNames.departures',
            defaultValue: 'quickQuote.subHeadings.departures',
            countValue: 'quickQuote.subHeadings.countDepartures',
            renderOrder: 2,
            wrapperLabel: 'quickQuote.filterLabels.departures'
        },
        'travel-party': {
            id: 'travel-party',
            headingKey: 'quickQuote.headings.travelParty',
            nameKey: 'quickQuote.filterNames.travelParty',
            defaultValueSingular: 'quickQuote.subHeadings.travelPartySingular',
            defaultValuePlural: 'quickQuote.subHeadings.travelPartyPlural',
            travelPartyAria: 'quickQuote.subHeadings.travelPartyAria',
            renderOrder: 3,
            wrapperLabel: 'quickQuote.filterLabels.travelParty'
        },
        'more-filters': {
            id: 'more-filters',
            groupKey: {
                theme: 'theme',
                ship: 'ship',
                night: 'night',
                newItineraries: 'new-itineraries'
            },
            headingKey: 'quickQuote.headings.moreFilters',
            nameKey: 'quickQuote.filterNames.moreFilters',
            renderOrder: 4,
            wrapperLabel: {
                night: 'quickQuote.filterLabels.night',
                ship: 'quickQuote.filterLabels.ship',
                theme: 'quickQuote.filterLabels.theme',
                'new-sailings': ''
            },
            positionDisplayed: {
                theme: 0,
                night: 1,
                ship: 2,
                'new-sailings': 3
            }
        },
        states: {
            disabled: 'quickQuote.filterStatus.disabled',
            selected: 'quickQuote.filterStatus.selected',
            unavailable: 'quickQuote.filterStatus.unavailable',
            unselected: 'quickQuote.filterStatus.unselected'
        },
        header: 'Header',
        subHeader: 'SubHeader',
        preSelectedFiltersSuffix: ';filterId=urlFriendlyId',
        separator: ','
    },
    specialFilters: [
        'specialOffer',
        'portOfCall'
    ],
    keyboardKeysCodes: {
        arrowLeft: 'ArrowLeft',
        arrowRight: 'ArrowRight',
        enter: 'Enter',
        escape: 'Escape',
        space: 'Space',
        tab: 'Tab'
    },
    translationKeys: {
        modal: {
            buttonClear: 'quickQuote.modal.buttonClear',
            buttonClearAria: 'quickQuote.modal.buttonClearAria',
            buttonClearAll: 'quickQuote.modal.buttonClearAll',
            buttonClose: 'quickQuote.modal.buttonClose',
            buttonCancel: 'quickQuote.modal.buttonCancel',
            buttonAfterFilter: 'quickQuote.modal.buttonAfterFilter',
            buttonDefault: 'quickQuote.modal.buttonDefault',
            titleDefault: 'quickQuote.modal.titleDefault'
        },
        bar: {
            findCruisesButton: 'quickQuote.buttons.buttonFindCruises'
        },
        wrapper: {
            buttonClearFilters: 'quickQuote.buttons.buttonClearFilters'
        },
        advancedBookingBanner: {
            filters: 'advancedBookingBanner.filters'
        },
        imagesPicker: 'quickQuote.imagesPicker.ariaLabel',
        gridPicker: 'gridPicker.ariaLabel',
        dynamicFilter: 'quickQuote.{{filterType}}Filter.{{key}}',
        subHeader: 'quickQuote.filterLabels.subtitleAria'
    },
    viewBreakpoints: {
        mobile: 767,
        desktop: 768
    },
    images: {
        placeholder: '/assets/images/img_placeholder_logo.svg',
    },
    ships: {
        'disney-wish': {
            name:  'Disney Wish',
            urlFriendlyId: 'disney-wish'
        }
    },
    specialtyNames: {
        SPOOKY: 'quickQuote.specialtyNames.SPOOKY',
        MDAS: 'quickQuote.specialtyNames.MDAS',
        MERRY: 'quickQuote.specialtyNames.MERRY',
        SWDAS: 'quickQuote.specialtyNames.SWDAS',
        PDAS: 'quickQuote.specialtyNames.PDAS',
        SDAS: 'quickQuote.specialtyNames.SDAS'
    },
    timeToFocusCloseButton: 500,
    timeToRenderQuickQuoteWrapper: 1000,
    travelPartyFilterKeys: {
        defaultValuePlural: 'defaultValuePlural',
        defaultValueSingular: 'defaultValueSingular'
    },
    urlDelimiters: {
        filteredPort: 'filteredPort=',
        hash: '#',
        ports: 'ports_'
    },
    localStorage: {
        specialFilters: 'special_filters'
    },
    specialFiltersTypes: {
        portOfCall: 'filteredPort',
        specialOffer: 'specialOffer'
    },
    filterTypes: {
        destination: 'filterType=destination',
        city: 'filterType=city',
        date: 'filterType=date'
    },
    toggles: {
        dclModifySailDate: 'dclModifySailDate',
        dclAdvancedBookingPhase2: 'dclAdvancedBookingPhase2',
        dclAdvancedBookingPhase3: 'dclAdvancedBookingPhase3',
        dclInternationalDirectPhase2: 'dclInternationalDirectPhase2'
    },
    allowedDynamicFilterId: {
        night:  'night'
    }
};
