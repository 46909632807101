import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ParamMap } from '@angular/router';
import { map } from 'rxjs/operators/map';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { of } from 'rxjs/observable/of';
import { GeolocationService } from '../geolocation/geolocation.service';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load
import { AnalyticsService, ConfigService as AnalyticsConfig} from '@wdpr/angular-analytics';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load

import { ConfigService } from '@app/core/config.service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { TrackLink } from '@interfaces/track-link';

@Injectable()
export class HomepageAnalyticsService {
    pageKey = false;
    scheduledAnalyticsTrackLinks: TrackLink[] = [];
    private loader: any;
    private siteId = 'dcl';
    private model: any;
    private environment = 'prod';

    constructor(
        private analyticsService: AnalyticsService,
        private analyticsConfig: AnalyticsConfig,
        private configService: ConfigService,
        private logger: LoggerService,
        private httpClient: HttpClient,
        private cookieService: CookieService
    ) {
        this.siteId = this.configService.getValue('siteId') || 'dcl';
        this.environment = this.configService.getValue('environment') || 'prod';
        this.customLoader();
        this.configureLangLocale();
    }

    initAnalytics() {
        this.analyticsService.getModel().then((model: any) => {
            this.analyticsService.trackPage(model);
        });
    }

    private customLoader() {
        // make changes to analytics configuration here
        this.analyticsConfig.loader = (this.siteId === 'hkdl' || this.siteId === 'dcl') ?
            () => this.httpClient.get('wdpr-web-homepage/analytics/' + this.siteId +
                '.site.homepage.' + this.environment + '.json').toPromise() :
            () => this.httpClient.get('wdpr-web-homepage/analytics/' + this.siteId + '.site.homepage.json').toPromise();
    }

    private configureLangLocale() {
        // override the default logic for hkdl
        if (this.siteId === 'hkdl' || this.siteId === 'dcl') {
            this.analyticsConfig.addGlobalProcessors({
                contentLocale: () => this.getLocale(),
                contentLanguage: () => this.getLanguage()
            });
        }
    }

    private getLocaleCookie() {
        try {
            return JSON.parse(this.cookieService.get('localeCookie_jar_aka'));
        } catch (ignore) {}

        return {};
    }

    private getLangLocale() {
        const cookieValue = this.getLocaleCookie();
        const value = cookieValue && cookieValue.contentLocale;

        if (value && typeof value === 'string') {
            if (value.length === 5 && value[2] === '_') {
                return value;
            } else if (value.length === 2) {
                return value + '_XX';
            }
        }
    }

    private getLocale() {
        const langLocale = this.getLangLocale();
        if (langLocale) {
            return langLocale.substr(3);
        }
    }

    private getLanguage() {
        const langLocale = this.getLangLocale();
        if (langLocale) {
            return langLocale.substr(0, 2);
        }
    }

    storyAnalytics(storyBlock) {
        var before: any = window.localStorage.getItem('analyticslist') ? window.localStorage.getItem('analyticslist') + ',' + storyBlock : window.localStorage.setItem('analyticslist', storyBlock);
        window.localStorage.setItem('analyticslist', before);
        var list;
        if (before) {
            list = before.split(',');
            if (list[0] === 'undefined') {
                list.shift();
            }
        }
        
        onbeforeunload = (event) => {
            window.localStorage.setItem('analyticslist', '');
            this.analyticsService.trackElement({ 
                linkId: 'SeenModulesList', 
                contentList: list
            });
        };
    }

    /**
     * Get the model, set the link ID, and send the model back for tracking
     * @param {string} linkId linkId to track
     * @param {object} modelProps Additional props to send in the trackLink call
     */
    trackLink(linkId: string, modelProps?: object): void {
        console.log('homepage analytics trackLink ', linkId);
        this.analyticsService.trackLink(Object.assign({}, modelProps, {linkId}));
        this.storyAnalytics(linkId);
    }
}
